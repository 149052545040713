import React, { useRef, useState } from "react";

import { Error as ErrorLayout } from "~/components/layouts";
import { SocialButton } from "~/components/presets";
import { submitWrapper } from "~/utils";
import { kratos } from "~/services";

import { useSettingFlow, useStatus } from "~/hooks";
import { useStore } from "~/states";
import { Captcha, getCaptchaToken } from "~/components/helpers/captcha";

import githubImgUrl from "@assets/logos/github.png?url";
import googleImgUrl from "@assets/logos/google.svg?url";
import microsoftImgUrl from "@assets/logos/microsoft.svg?url";
import linkedinImgUrl from "@assets/logos/linkedin.svg?url";
import facebookImgUrl from "@assets/logos/facebook.svg?url";
import { config } from "~/const";

// eslint-disable-next-line complexity
export default () => {
  const returnTo = useStore((state) => state.returnTo);
  const status = useStatus();
  const [processing, setProcessing] = useState(false);
  const [flow, flowError] = useSettingFlow({ returnTo });
  const captchaRef = useRef<Captcha>(null);

  if (flowError) {
    return <ErrorLayout text={flowError} goBackUrl={window.location.pathname} />;
  }

  // Waits the flow state to be loaded before displaying anything
  if (!flow) return null;

  const submitWrapperProps = {
    setProcessing,
    processing,
    setError: status.setError,
  };

  const onLink = submitWrapper(
    submitWrapperProps,
    async (provider: "google" | "github" | "microsoft" | "linkedin" | "facebook") => {
      const res = await kratos.linkOidc({
        ...flow,
        provider,
        headers: {
          "x-captcha-token": await getCaptchaToken(captchaRef),
        },
      });

      sessionStorage.setItem(flow.flow, "link");
      window.location.assign(res.url);
    }
  );

  const onUnlink = submitWrapper(
    submitWrapperProps,
    async (provider: "google" | "github" | "microsoft" | "linkedin" | "facebook") => {
      await kratos.unlinkOidc({
        ...flow,
        provider,
        headers: {
          "x-captcha-token": await getCaptchaToken(captchaRef),
        },
      });

      window.location.reload();
    }
  );

  return (
    <div className="m-4 flex flex-col items-center w-full">
      <Captcha ref={captchaRef} />
      <h2 className="font-medium text-3xl text-center mb-4">Link account</h2>
      <div className="flex flex-col w-full gap-3 max-w-xs">
        {flow.oidc.github && (
          <SocialButton
            onClick={() => (flow.oidc.github === "link" ? onLink("github") : onUnlink("github"))}
            icon={githubImgUrl}
            variant="zinc"
            text={`${flow.oidc.github === "link" ? "Link" : "Unlink"} Github Account`}
          />
        )}
        {flow.oidc.google && (
          <SocialButton
            onClick={() => (flow.oidc.google === "link" ? onLink("google") : onUnlink("google"))}
            icon={googleImgUrl}
            variant="blue"
            text={`${flow.oidc.google === "link" ? "Link" : "Unlink"} Google Account`}
          />
        )}
        {flow.oidc.microsoft && (
          <SocialButton
            onClick={() => (flow.oidc.microsoft === "link" ? onLink("microsoft") : onUnlink("microsoft"))}
            icon={microsoftImgUrl}
            variant="blue"
            text={`${flow.oidc.microsoft === "link" ? "Link" : "Unlink"} Microsoft Account`}
          />
        )}
        {flow.oidc.linkedin && (
          <SocialButton
            onClick={() => (flow.oidc.linkedin === "link" ? onLink("linkedin") : onUnlink("linkedin"))}
            icon={linkedinImgUrl}
            variant="blue"
            text={`${flow.oidc.linkedin === "link" ? "Link" : "Unlink"} LinkedIn Account`}
          />
        )}
        {flow.oidc.facebook && (
          <SocialButton
            onClick={() => (flow.oidc.facebook === "link" ? onLink("facebook") : onUnlink("facebook"))}
            icon={facebookImgUrl}
            variant="blue"
            text={`${flow.oidc.facebook === "link" ? "Link" : "Unlink"} Facebook Account`}
          />
        )}
        <a
          className="text-blue-600 text-xs hover:underline font-normal self-center mt-8"
          href={flow.returnTo ?? config.defaultRedirect}
        >
          Go Back
        </a>
      </div>
    </div>
  );
};
