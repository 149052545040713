import { Configuration, FrontendApi, UiNodeInputAttributes, UiNode, RegistrationFlow } from "@ory/kratos-client";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { config, routes } from "~/const";
import { KratosClientError } from "./kratos/errors";

const kratosConfig = new Configuration({ basePath: config.kratosUrl });
const kratos = new FrontendApi(kratosConfig);

export const REFRESH_DIFFERENTIAL_MS = 5000;

type KratosFlowResult = {
  csrf: string;
  flow: string;
  returnTo?: string;
  expiresAt: Date;
};

type KratosClientResult = {
  returnTo?: string;
  active?: boolean;
};

export const formatReturnTo = (returnTo: string) => `return_to=${encodeURIComponent(returnTo)}`;

export const getReloginUrl = (returnTo?: string) =>
  `${routes.login}?refresh=true${returnTo ? `&${formatReturnTo(returnTo)}` : ""}`;

/**
 * Get the time in millisecond before the flow needs to be refreshed
 * @param expiresAt expiration date
 * @returns the time before refreshing
 */
export const refreshTimeInMs = (expiresAt: Date) => {
  const now = new Date();
  const diff = expiresAt.getTime() - now.getTime();
  return diff - REFRESH_DIFFERENTIAL_MS; // Refresh 5 seconds before the expiration time
};

const getCsrfToken = (flow: AxiosResponse<{ ui?: { nodes?: UiNode[] } }>) => {
  const csrfNode = flow.data.ui?.nodes?.find((node) => {
    if (node.type !== "input") {
      return false;
    }

    return (node.attributes as UiNodeInputAttributes)?.name === "csrf_token";
  });

  return ((csrfNode?.attributes as UiNodeInputAttributes)?.value as string) ?? "";
};

type InitLoginFlowProps = {
  returnTo?: string;
  refresh: boolean;
};

export const initLoginFlow = async ({ returnTo, refresh }: InitLoginFlowProps): Promise<KratosFlowResult> => {
  const flow = await kratos.createBrowserLoginFlow({ refresh, returnTo });

  KratosClientError.throwIfErrorFound(flow);

  return {
    csrf: getCsrfToken(flow),
    flow: flow.data.id,
    returnTo: flow.data.return_to,
    expiresAt: new Date(flow.data.expires_at),
  };
};

type InitRegisterFlowProps = {
  returnTo?: string;
};

const toPathString = function (url: URL) {
  return url.pathname + url.search + url.hash;
};

const setSearchParams = function (url: URL, ...objects: any) {
  const searchParams = new URLSearchParams(url.search);
  for (const object of objects) {
    for (const key in object) {
      if (Array.isArray(object[key])) {
        searchParams.delete(key);
        for (const item of object[key]) {
          searchParams.append(key, item);
        }
      } else {
        searchParams.set(key, object[key]);
      }
    }
  }
  url.search = searchParams.toString();
};

/**
 * Copied over from the kratos client because the after_verification_return_to is not supported by the kratos client
 * out of the box
 */
const initializeSelfServiceRegistrationFlowForBrowsers = async (
  returnTo?: string,
  afterVerificationReturnTo?: string,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<RegistrationFlow>> => {
  const configuration = kratosConfig;

  const localVarPath = `/self-service/registration/browser`;
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, "https://example.com");
  let baseOptions;
  if (configuration) {
    baseOptions = configuration.baseOptions;
  }
  const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
  const localVarHeaderParameter = {};
  const localVarQueryParameter: Record<string, string> = {};
  if (returnTo !== undefined) {
    localVarQueryParameter["return_to"] = returnTo;
  }
  if (afterVerificationReturnTo !== undefined) {
    localVarQueryParameter["after_verification_return_to"] = afterVerificationReturnTo;
  }
  setSearchParams(localVarUrlObj, localVarQueryParameter);
  const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
  localVarRequestOptions.headers = Object.assign(
    Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions),
    options.headers
  );
  const axiosArgs = {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  };

  const axiosRequestArgs = Object.assign(Object.assign({}, axiosArgs.options), {
    url: configuration.basePath + axiosArgs.url,
  });
  return axios.request(axiosRequestArgs);
};

export const initRegisterFlow = async ({ returnTo }: InitRegisterFlowProps): Promise<KratosFlowResult> => {
  const afterVerificationReturnTo = new URL(routes.login, window.location.origin);
  if (returnTo) {
    afterVerificationReturnTo.searchParams.set("return_to", returnTo);
  }

  const flow = await initializeSelfServiceRegistrationFlowForBrowsers(returnTo, afterVerificationReturnTo.toString());

  KratosClientError.throwIfErrorFound(flow);

  return {
    csrf: getCsrfToken(flow),
    flow: flow.data.id,
    returnTo: flow.data.return_to,
    expiresAt: new Date(flow.data.expires_at),
  };
};

type GetRegisterFlowProps = {
  id: string;
};

export const getRegisterFlow = async ({ id }: GetRegisterFlowProps): Promise<KratosFlowResult> => {
  const flow = await kratos.getRegistrationFlow({ id });

  KratosClientError.throwIfErrorFound(flow);

  return {
    csrf: getCsrfToken(flow),
    flow: flow.data.id,
    returnTo: flow.data.return_to,
    expiresAt: new Date(flow.data.expires_at),
  };
};

type GetLoginFlowProps = {
  id: string;
};

export const getLoginFlow = async ({ id }: GetLoginFlowProps): Promise<KratosFlowResult> => {
  const flow = await kratos.getLoginFlow({ id });

  KratosClientError.throwIfErrorFound(flow);

  return {
    csrf: getCsrfToken(flow),
    flow: flow.data.id,
    returnTo: flow.data.return_to,
    expiresAt: new Date(flow.data.expires_at),
  };
};

export const initLogoutFlow = async () => {
  const flow = await kratos.createBrowserLogoutFlow();

  return {
    token: flow.data.logout_token,
  };
};

type InitRecoveryFlowProps = {
  returnTo?: string;
};

export const initRecoveryFlow = async ({ returnTo }: InitRecoveryFlowProps): Promise<KratosFlowResult> => {
  const flow = await kratos.createBrowserRecoveryFlow({ returnTo });

  return {
    csrf: getCsrfToken(flow),
    flow: flow.data.id,
    returnTo: flow.data.return_to,
    expiresAt: new Date(flow.data.expires_at),
  };
};

type InitSettingFlowProps = {
  returnTo?: string;
};

type InitSettingsFlowResult = KratosFlowResult & {
  oidc: { [key: string]: "link" | "unlink" };
  success?: boolean;
};

const isOperationValid = (operation: string) => operation === "link" || operation === "unlink";

export const initSettingFlow = async ({ returnTo }: InitSettingFlowProps): Promise<InitSettingsFlowResult> => {
  const flow = await kratos.createBrowserSettingsFlow({ returnTo });

  const result: InitSettingsFlowResult = {
    csrf: getCsrfToken(flow),
    flow: flow.data.id,
    returnTo: flow.data.return_to,
    expiresAt: new Date(flow.data.expires_at),
    oidc: {},
  };

  flow.data.ui.nodes.forEach((node) => {
    if (node.group === "oidc" && node.type === "input") {
      const provider = (node.attributes as any)?.value;
      const operation = (node.attributes as any)?.name;

      if (provider && isOperationValid(operation)) {
        result.oidc[provider] = operation;
      }
    }
  });

  return result;
};

type InitSettingsFlowProps = {
  id: string;
};

export const getSettingFlow = async ({ id }: InitSettingsFlowProps): Promise<InitSettingsFlowResult> => {
  const flow = await kratos.getSettingsFlow({ id });

  KratosClientError.throwIfErrorFound(flow);

  return {
    csrf: getCsrfToken(flow),
    flow: flow.data.id,
    returnTo: flow.data.return_to,
    expiresAt: new Date(flow.data.expires_at),
    success: flow.data.state === "success",
    oidc: {},
  };
};

type LoginProps = {
  csrf: string;
  email: string;
  password: string;
  flow: string;
  returnTo?: string;
  headers?: { [key: string]: string };
};

export const login = async ({
  password,
  email,
  flow,
  csrf,
  returnTo,
  headers,
}: LoginProps): Promise<KratosClientResult> => {
  return await kratos
    .updateLoginFlow(
      {
        flow,
        updateLoginFlowBody: {
          csrf_token: csrf,
          method: "password",
          password,
          identifier: email,
        },
      },
      { headers }
    )
    .then((res) => ({
      active: !!res.data.session,
      returnTo,
    }))
    .catch((e) => {
      throw KratosClientError.fromError(e);
    });
};

type LoginOidcProps = {
  csrf: string;
  provider: "google" | "github" | "microsoft" | "linkedin" | "facebook";
  flow: string;
  returnTo?: string;
  headers?: { [key: string]: string };
};

export const loginOidc = async ({ provider, flow, csrf, headers }: LoginOidcProps) => {
  return await kratos
    .updateLoginFlow(
      {
        flow,
        updateLoginFlowBody: {
          csrf_token: csrf,
          method: "oidc",
          provider,
        },
      },
      { headers }
    )
    .then(() => {
      throw new Error("Redirect required");
    })
    .catch((e) => {
      if (e?.response?.data?.redirect_browser_to) {
        return {
          url: e.response.data.redirect_browser_to as string,
        };
      }

      throw KratosClientError.fromError(e);
    });
};

export type RegisterProps = {
  csrf: string;
  email: string;
  password: string;
  flow: string;
  returnTo?: string;
  headers?: { [key: string]: string };
};

export const register = async ({
  password,
  email,
  flow,
  csrf,
  returnTo,
  headers,
}: RegisterProps): Promise<KratosClientResult> => {
  return await kratos
    .updateRegistrationFlow(
      {
        flow,
        updateRegistrationFlowBody: {
          csrf_token: csrf,
          method: "password",
          password,
          traits: {
            email,
          },
        },
      },
      { headers }
    )
    .then((res) => ({
      active: !!res.data.identity,
      returnTo,
    }))
    .catch((e) => {
      throw KratosClientError.fromError(e);
    });
};

type RegisterOidcProps = {
  csrf: string;
  provider: "google" | "github" | "microsoft" | "linkedin" | "facebook";
  flow: string;
  returnTo?: string;
  headers?: { [key: string]: string };
};

export const registerOidc = async ({ provider, flow, csrf, headers }: RegisterOidcProps) => {
  return await kratos
    .updateRegistrationFlow(
      {
        flow,
        updateRegistrationFlowBody: {
          csrf_token: csrf,
          method: "oidc",
          provider,
        },
      },
      { headers }
    )
    .then(() => {
      throw new Error("redirect required");
    })
    .catch((e) => {
      if (e?.response?.data?.redirect_browser_to) {
        return {
          url: e.response.data.redirect_browser_to as string,
        };
      }

      throw KratosClientError.fromError(e);
    });
};

export const logout = async () => {
  const flow = await kratos.createBrowserLogoutFlow();
  await kratos.updateLogoutFlow({
    token: flow.data.logout_token,
  });
};

type RecoverProps = {
  csrf: string;
  email: string;
  flow: string;
  returnTo?: string;
  headers?: { [key: string]: string };
};

export const recover = async ({ flow, csrf, email, returnTo, headers }: RecoverProps): Promise<KratosClientResult> => {
  return await kratos
    .updateRecoveryFlow(
      {
        flow,
        updateRecoveryFlowBody: {
          csrf_token: csrf,
          email,
          method: "link",
        },
      },
      { headers }
    )
    .then(() => ({
      returnTo,
    }))
    .catch((e) => {
      throw KratosClientError.fromError(e);
    });
};

type SettingProps = {
  csrf: string;
  password: string;
  flow: string;
  returnTo?: string;
  headers?: { [key: string]: string };
};

export const updateSettings = async ({
  flow,
  csrf,
  password,
  returnTo,
  headers,
}: SettingProps): Promise<KratosClientResult> => {
  return await kratos
    .updateSettingsFlow(
      {
        flow,
        updateSettingsFlowBody: {
          csrf_token: csrf,
          password,
          method: "password",
        },
      },
      { headers }
    )
    .then(() => ({
      returnTo,
    }))
    .catch((e) => {
      throw KratosClientError.fromError(e);
    });
};

type LinkOidcProps = {
  csrf: string;
  provider: "google" | "github" | "microsoft" | "linkedin" | "facebook";
  flow: string;
  headers?: { [key: string]: string };
};

export const linkOidc = async ({ flow, csrf, provider, headers }: LinkOidcProps) => {
  return await kratos
    .updateSettingsFlow(
      {
        flow,
        updateSettingsFlowBody: {
          link: provider,
          csrf_token: csrf,
          method: "oidc",
        },
      },
      { headers }
    )
    .then(() => {
      throw new Error("redirect required");
    })
    .catch((e) => {
      if (e?.response?.data?.redirect_browser_to) {
        return {
          url: e.response.data.redirect_browser_to as string,
        };
      }

      throw KratosClientError.fromError(e);
    });
};

type UnlinkOidcProps = {
  csrf: string;
  provider: "google" | "github" | "microsoft" | "linkedin" | "facebook";
  flow: string;
  headers?: { [key: string]: string };
};

export const unlinkOidc = async ({ flow, csrf, provider, headers }: UnlinkOidcProps) => {
  await kratos
    .updateSettingsFlow(
      {
        flow,
        updateSettingsFlowBody: {
          unlink: provider,
          csrf_token: csrf,
          method: "oidc",
        },
      },
      { headers }
    )
    .catch((e) => {
      throw KratosClientError.fromError(e);
    });
};

type VerificationProps = {
  returnTo?: string;
  email: string;
};

export const sendVerification = async ({ returnTo, email }: VerificationProps): Promise<KratosClientResult> => {
  const flow = await kratos.createBrowserVerificationFlow();

  return await kratos
    .updateVerificationFlow({
      flow: flow.data.id,
      updateVerificationFlowBody: {
        csrf_token: getCsrfToken(flow),
        method: "link",
        email,
      },
    })
    .then(() => ({
      returnTo,
    }))
    .catch((e) => {
      throw KratosClientError.fromError(e);
    });
};

export const getError = async (id: string) => {
  const res = await kratos.getFlowError({ id });
  return res.data;
};

export const getAuth = async () => {
  const res = await kratos.toSession();
  return res.data;
};

export const getVerificationFlow = async ({ id }: { id: string }) => {
  const flow = await kratos.getVerificationFlow({ id });

  KratosClientError.throwIfErrorFound(flow);

  return flow.data;
};
