import React, { useEffect, useRef, useState } from "react";

import { Card, Link, Error as ErrorMessage, Success, Warning } from "~/components/elements";
import { Logo, SocialButton } from "~/components/presets";
import { Error as ErrorLayout } from "~/components/layouts";
import { config, routes } from "~/const";
import { kratos } from "~/services";
import { submitWrapper, getValueFromSearch } from "~/utils";

import { useLoginFlow, useStatus } from "~/hooks";
import { useStore } from "~/states";
import { KratosClientError } from "~/services/kratos/errors";
import { Captcha, getCaptchaToken } from "~/components/helpers/captcha";
import { LoginForm, LoginFields } from "~/components/forms";

import githubImgUrl from "@assets/logos/github.png?url";
import googleImgUrl from "@assets/logos/google.svg?url";
import microsoftImgUrl from "@assets/logos/microsoft.svg?url";
import linkedinImgUrl from "@assets/logos/linkedin.svg?url";
import facebookImgUrl from "@assets/logos/facebook.svg?url";
import { formatReturnTo } from "~/services/kratos";
import { analytics } from "~/segment";
import * as Sentry from "@sentry/react";
import { useAuth } from "~/hooks/auth";

const LOGIN_FAILED = "Login Failed";
const METHOD_PASSWORD = "password";

const ALREADY_LOGGED_IN_ERROR_MESSAGE =
  "A valid session was detected and thus login is not possible. Did you forget to set `?refresh=true`?";

// eslint-disable-next-line max-lines-per-function
export default () => {
  const { data: auth } = useAuth();
  const { returnTo } = useStore();
  const captchaRef = useRef<Captcha>(null);

  const isRefreshed = getValueFromSearch("refresh") === "true";

  const [refresh, setRefresh] = useState(isRefreshed);
  const [showSpinner, setShowSpinner] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [unactivatedEmail, setUnactivatedEmail] = useState<string>();
  const [flow, flowError] = useLoginFlow({ returnTo, refresh, id: getValueFromSearch("flow") });

  const status = useStatus({});

  useEffect(() => {
    // if there is no active session set the refresh param to false
    if (refresh && auth && !auth.active) {
      setRefresh(false);
    }
  }, [auth]);

  if (flowError) {
    const prefix = "Login flow error:";
    console.error(prefix, flowError);
    Sentry.captureException(new Error(`${prefix} ${flowError}`), { tags: { origin: "kratos" } });
    return <ErrorLayout text={flowError} goBackUrl={window.location.pathname} />;
  }

  // Waits the flow & auth states to be loaded before displaying anything
  if (!(flow && auth)) {
    // eslint-disable-next-line no-console
    console.debug("Waiting for flow and auth states to be loaded");
    return null;
  }

  const submitWrapperProps = {
    setProcessing,
    processing,
    setError: status.setError,
    preSubmit: () => {
      setShowSpinner(true);
      setUnactivatedEmail(undefined);
    },
    postSubmit: () => {
      setShowSpinner(false);
    },
  };

  const onSubmit = submitWrapper(submitWrapperProps, async (values: LoginFields) => {
    const res = await kratos
      .login({
        ...values,
        ...flow,
        headers: {
          "x-captcha-token": await getCaptchaToken(captchaRef),
        },
      })
      .catch(async (e) => {
        if (!KratosClientError.isKratosClientError(e)) {
          Sentry.captureException(e, { extra: { method: METHOD_PASSWORD } });
          await analytics.track(LOGIN_FAILED, { method: METHOD_PASSWORD, reason: "unknown" });
          throw e;
        }

        if (e.type === "ErrorValidationAddressNotVerified") {
          // Treat this as a warning rather than an error.
          status.setWarning(e.message);
          setUnactivatedEmail(values.email);
          return;
        }

        if (e.type === "ErrorValidationInvalidCredentials") {
          status.setWarning(e.message);
          return;
        }

        if (e.type === "ErrorValidationGeneric") {
          if (e.error.text === ALREADY_LOGGED_IN_ERROR_MESSAGE) {
            window.location.reload();
          }

          status.setWarning(e.message);
          return;
        }

        await analytics.trackKratosError({ error: e, eventName: LOGIN_FAILED, method: METHOD_PASSWORD });

        throw e;
      });

    if (!res) {
      return;
    }

    await analytics.track("Login Succeeded", { method: METHOD_PASSWORD });

    window.location.replace(res.returnTo ?? config.defaultRedirect);
  });

  const onLoginOidcWrapperProps = {
    ...submitWrapperProps,
    preSubmit: () => setUnactivatedEmail(undefined),
    postSubmit: undefined,
  };

  const onLoginOidc = submitWrapper(
    onLoginOidcWrapperProps,
    async (provider: "google" | "github" | "microsoft" | "linkedin" | "facebook") => {
      const res = await kratos
        .loginOidc({
          ...flow,
          provider,
          headers: {
            "x-captcha-token": await getCaptchaToken(captchaRef),
          },
        })
        .catch(async (e) => {
          if (KratosClientError.isKratosClientError(e)) {
            if (e.type === "ErrorValidationGeneric" && e.error.text === ALREADY_LOGGED_IN_ERROR_MESSAGE) {
              window.location.reload();
            }

            await analytics.trackKratosError({ error: e, eventName: LOGIN_FAILED, method: provider });
          } else {
            await analytics.track(LOGIN_FAILED, { method: provider, reason: e.type });
          }

          throw e;
        });

      await analytics.track("Login Succeeded", { method: provider });

      window.location.assign(res.url);
    }
  );

  return (
    <div className="m-4 flex flex-col items-center w-full h-full">
      <Captcha ref={captchaRef} />
      <h2 className="font-medium text-3xl text-center mb-8">
        <span>{refresh ? "Relogin" : "Login"}</span> to Botpress Cloud
      </h2>

      <div className="flex flex-col w-full max-w-xs gap-3">
        <SocialButton
          onClick={() => onLoginOidc("github")}
          icon={githubImgUrl}
          variant="zinc"
          text="Continue with GitHub"
        />
        <SocialButton
          onClick={() => onLoginOidc("google")}
          icon={googleImgUrl}
          variant="blue"
          text="Continue with Google"
        />
        <SocialButton
          onClick={() => onLoginOidc("microsoft")}
          icon={microsoftImgUrl}
          variant="blue"
          text="Continue with Microsoft"
        />
        <SocialButton
          onClick={() => onLoginOidc("linkedin")}
          icon={linkedinImgUrl}
          variant="blue"
          text="Continue with LinkedIn"
        />
        <SocialButton
          onClick={() => onLoginOidc("facebook")}
          icon={facebookImgUrl}
          variant="blue"
          text="Continue with Facebook"
        />
        <hr className="border-t my-4" />
        {status.error && <ErrorMessage>{status.error}</ErrorMessage>}
        {status.success && <Success>{status.success}</Success>}
        {status.warning && (
          <Warning>
            {status.warning}
            {unactivatedEmail && (
              <span className="ml-2">
                <a href={`/verification?email=${encodeURIComponent(unactivatedEmail)}`}>Click here</a> if you need to
                request a new verification email.
              </span>
            )}
          </Warning>
        )}
        <LoginForm
          onSubmit={onSubmit}
          showForgotPassword={!refresh}
          formType="login"
          disableSubmit={processing}
          showSpinner={showSpinner}
        />

        <div className="pt-8 flex flex-col">
          {refresh ? (
            <p className="text-xs self-center">
              <Link tabIndex={0} to={routes.logout}>
                Logout
              </Link>{" "}
              from Botpress
            </p>
          ) : (
            <p className="text-xs self-center">
              New to Botpress ?{" "}
              <Link tabIndex={0} to={routes.registration + (returnTo ? `?${formatReturnTo(returnTo)}` : "")}>
                Sign up
              </Link>
            </p>
          )}
        </div>
        <Logo className="mt-16" height={24} />
      </div>
    </div>
  );
};
